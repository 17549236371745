<template>
    <b-row class="mb-3 mx-0">
        <b-col md="3" class="fs-14 grey-color d-flex align-items-center fw-700 px-5px">{{ label }}</b-col>
        <b-col md="9" class="fs-14 grey-color px-5px">{{ value }}</b-col>
    </b-row>
</template>

<script>
export default {
    name: 'form-row-show-text',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        id() {
            return 'form_row_show_text_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
.px-5px {
    padding-left: 5px;
    padding-right: 5px;
}
</style>
