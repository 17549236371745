var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-3 mx-0" },
    [
      _c(
        "b-col",
        {
          staticClass:
            "fs-14 grey-color d-flex align-items-center fw-700 px-5px",
          attrs: { md: "3" },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _c(
        "b-col",
        { staticClass: "fs-14 grey-color px-5px", attrs: { md: "9" } },
        [_vm._v(_vm._s(_vm.value))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }